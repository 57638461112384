import React from 'react';

import { DefaultLayout } from '../layouts/';

const NotFoundPage = () => (
  <DefaultLayout>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </DefaultLayout>
);

export default NotFoundPage;
